import { motion } from "framer-motion";
import {
  SupabaseClient,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
import { AiOutlineDollarCircle, AiOutlineUser } from "react-icons/ai";
import { TbLogout, TbScanEye } from "react-icons/tb";
import { MdOutlineDashboard } from "react-icons/md";
import { FaBolt } from "react-icons/fa";
import { removeAllCookies } from "@/utils/remove";
import useProfile from "@/hooks/useProfile";

interface AuthUserDropDownProps {}

const AuthUserDropDown: FC<AuthUserDropDownProps> = ({}) => {
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const profile = useProfile();
  //animations states
  const initialState = { opacity: 0 };
  const animateTo = {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  };
  const signoutuser = async () => {
    // Use JS cookie to clear all supabase auth cookies

    // loop through and remove all cookies using js cookie
    await removeAllCookies();
    await supabaseClient.auth.signOut();

    // Use supabase client to clear local storage
    localStorage.removeItem("session");

    return window.location.reload();
  };

  return (
    <motion.div
      className="w-[200px] bg-white shadow-md absolute top-[50px] left-1/2  -translate-x-[85%] z-50 border border-gray-200 rounded-lg"
      initial={initialState}
      animate={animateTo}
    >
      <nav>
        <ul>
          {profile?.onboarding && (
            <>
              <li className="my-2 hover:bg-gray-50 group cursor-pointer  ">
                <a
                  href="https://instantapply.co/dashboard"
                  className="px-4 py-2 md:hidden  text-sm  flex items-center  text-gray-600 font-medium hover:text-gray-800"
                >
                  <MdOutlineDashboard className="w-4 h-4 mr-2" />
                  Dashboard
                </a>
              </li>
              <li className="my-2 hover:bg-gray-50 group cursor-pointer  ">
                <Link
                  className="px-4 py-2 md:hidden  text-sm  flex items-center  text-gray-600 font-medium hover:text-gray-800"
                  href="/ai"
                >
                  <FaBolt className="w-4 h-4 mr-2" />
                  AI Cover Letter
                </Link>
              </li>
              <li className="my-2 hover:bg-gray-50 group cursor-pointer  ">
                <Link
                  className="px-4 py-2 md:hidden  text-sm  flex items-center  text-gray-600 font-medium hover:text-gray-800"
                  href="/ai"
                >
                  <TbScanEye className="w-4 h-4 mr-2" />
                 Resume Analyzer
                </Link>
              </li>
              <li className="my-2 hover:bg-gray-50 group cursor-pointer">
                <Link
                  href="/profile"
                  className="px-4 py-2 text-sm  flex items-center text-gray-500 group-hover:text-gray-600"
                >
                  <AiOutlineUser className="w-4 h-4 mr-2" />
                  Your Profile
                </Link>
              </li>
            </>
          )}
          <li className="my-2 hover:bg-gray-50 group cursor-pointer  ">
            <Link
              href="/pricing"
              className="px-4 py-2  text-sm  flex items-center  text-gray-500 group-hover:text-gray-600"
            >
              <AiOutlineDollarCircle className="w-4 h-4 mr-2" />
              Pricing
            </Link>
          </li>
          <li
            className="my-2 hover:bg-gray-50 group cursor-pointer  "
            onClick={signoutuser}
          >
            <span className="px-4 py-2  text-sm  flex items-center  text-gray-500 group-hover:text-gray-600">
              <TbLogout className="w-4 h-4 mr-2" />
              Logout
            </span>
          </li>
        </ul>
      </nav>
    </motion.div>
  );
};

export default AuthUserDropDown;
