import Image from "next/image";
import { FC, useState } from "react";
import AuthUserDropDown from "./AuthUserDropDown";
import useAuth from "@/hooks/useAuth";
import { BiChevronDown } from "react-icons/bi";
import useProfile from "@/hooks/useProfile";
interface AuthUserProps {}

const AuthUser: FC<AuthUserProps> = ({}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const user = useAuth() as any;

  return (
    <div
      className="flex relative items-center  rounded-md gap-1 group hover:bg-gray-50 p-2  cursor-pointer"
      onClick={() => toggleIsOpen(!isOpen)}
    >
      <p className="text-sm hidden sm:block text-gray-600 font-semibold">
        Hi {user?.user_metadata?.full_name.split(" ")[0]},
      </p>
      <div className="relative rounded-full w-[30px] h-[30px]  border border-gray-400">
        <Image
          fill
          src={user?.user_metadata?.avatar_url}
          alt="hero-img"
          style={{ objectFit: "cover", borderRadius: "50%" }}
        />
      </div>
      <BiChevronDown className="w-4 h-4 ml-2" />
      {isOpen && <AuthUserDropDown />}
    </div>
  );
};

export default AuthUser;
