import useAuth from "@/hooks/useAuth";
import useCredits from "@/hooks/useCredits";
import Image from "next/image";
import Link from "next/link";
import { AiFillStar } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import AuthUser from "./AuthUser";
import useProfile from "@/hooks/useProfile";
import { PiCoins } from "react-icons/pi";
import { useRouter } from "next/router";
import { FaCoins } from "react-icons/fa";
import { BiGlobe } from "react-icons/bi";

function Header() {
  const user = useAuth();
  const { credits, coins } = useCredits();
  const profile = useProfile();
  const router = useRouter();

  return (
    <>
      <header className="border-b bg-white border-gray-200 shadow-sm fixed top-0 left-0 w-full z-[100]">
        <nav className="bg-instant-light-100 border-gray-200 dark:bg-gray-900 ">
          <div className="max-w-[1400px] flex flex-wrap items-center justify-between mx-auto p-4 ">
            <a
              href="https://instantapply.co"
              className="inline-flex items-centerrelative z-50"
            >
              <Image
                width={24}
                height={24}
                src="/assets/images/instantapply-logo.svg"
                className="mr-1 h-6 sm:h-9 "
                alt="InstantApply Logo"
              />
              <span className="self-center hidden md:block font-silka text-md font-medium whitespace-nowrap dark:text-white">
                InstantApply
              </span>
            </a>
            <div className="flex md:order-2 relative z-50">
              {user ? (
                <>
                  <>
                    {profile?.onboarding && (
                      <>
                        <p className="px-2 md:px-4 py-2 flex  text-sm   items-center  text-gray-600 font-medium hover:text-gray-800">
                          <FaCoins className="w-4 h-4 mr-2 text-yellow-300" />
                          {/* {profile?.event === "subscription.create" ? (
													"PRO"
												) : (
													<>{`${coins} email coin${coins > 1 ? "s" : ""}`}</>
												)} */}

                          <>{`${coins} email coin${coins > 1 ? "s" : ""}`}</>
                        </p>
                        <p className="px-2 md:px-4 py-2 flex  text-sm   items-center  text-gray-600 font-medium hover:text-gray-800">
                          <AiFillStar className="w-4 h-4 mr-2 text-yellow-300" />
                          {/* {profile?.event === "subscription.create" ? (
													"PRO"
												) : (
													<>{`${credits} credit${credits > 1 ? "s" : ""}`}</>
												)} */}
                          {<>{`${credits} credit${credits > 1 ? "s" : ""}`}</>}
                        </p>
                        <>
                          {router.pathname !== "/dashboard" && (
                            <a
                              href="https://instantapply.co/dashboard"
                              className="px-4 py-2 hidden md:flex  text-sm   items-center  text-gray-600 font-medium hover:text-gray-800"
                            >
                              <MdOutlineDashboard className="w-4 h-4 mr-2" />
                              Dashboard
                            </a>
                          )}
                        </>
                      </>
                    )}
                  </>
                  {router.pathname == "/resume-analyzer" ||
                    (router.pathname == "/ai" && (
                      <Link
                        href="/onboarding"
                        className="px-4 py-2 hidden md:flex  text-sm   items-center  text-gray-600 font-medium hover:text-gray-800"
                      >
                        <BiGlobe className="w-4 h-4 mr-2" />
                        Onboarding
                      </Link>
                    ))}
                  <AuthUser />
                </>
              ) : (
                <Link href="/signin">
                  <button
                    type="button"
                    className="text-white bg-instant-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Get started
                  </button>
                </Link>
              )}
              {/* <button
                data-collapse-toggle="navbar-cta"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-cta"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button> */}
            </div>
            {/* <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-cta">
    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-instant-light-100 rounded-lg bg-instant-light-100 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-instant-light-100 dark:bg-instant-light-100 md:dark:bg-instant-light-100 dark:border-instant-light-100">
      <li>
        <a href="#" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">Home</a>
      </li>
      <li>
        <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
      </li>
      <li>
        <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</a>
      </li>
      <li>
        <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
      </li>
    </ul>
  </div> */}
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
